import { contentful } from "@/lib/contentful";
import { themes } from "@/styles/themes";
import Vue from "vue";
import { Module } from "vuex";

type TEventsLoading = "all" | "current";

export const events: Module<IEventsState, IRootState> = {
  namespaced: true,

  state: {
    allLoading: false,
    currentLoading: false,
  },

  getters: {
    theme(state) {
      if (!state.currentEvent) {
        return themes.default;
      }
      const theme = themes[state.currentEvent.fields.theme];
      return theme ? theme : themes.default;
    },

    currentEventSections(state) {
      if (!state.currentEvent) {
        return [];
      }

      return state.currentEvent.fields.sections.filter(s => !!s.fields);
    },
  },

  mutations: {
    setAll(state, payload: IEvent[]) {
      Vue.set(state, "allEvents", payload);
    },
    setCurrent(state, payload: IEvent) {
      Vue.set(state, "currentEvent", payload);
    },
    started(state, type: TEventsLoading) {
      Vue.set(state, `${type}Loading`, true);
    },
    finished(state, type: TEventsLoading) {
      Vue.set(state, `${type}Loading`, false);
    },
  },

  actions: {
    async fetchCurrent({ commit }, slug: string) {
      commit("started", "current");
      const entries = await contentful.getEntries<IEvent>({
        content_type: "event",
        "fields.slug": slug,
        include: 2,
      });
      if (entries.total > 0) {
        commit("setCurrent", entries.items[0]);
      }
      commit("finished", "current");
    },

    async fetchAll({ commit }) {
      commit("started", "all");
      const entries = await contentful.getEntries<IEvent>({
        content_type: "event",
      });
      commit("setAll", entries.items);
      commit("finished", "all");
    },
  },
};
