import { createClient, CreateClientParams } from "contentful";

const options: CreateClientParams = {
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN!,
  space: process.env.VUE_APP_CONTENTFUL_SPACE_ID!,
};

if (process.env.VUE_APP_CONTENTFUL_HOST) {
  options.host = process.env.VUE_APP_CONTENTFUL_HOST;
}

export const contentful = createClient(options);
