// tslint:disable object-literal-sort-keys
import { linearGradient, percent } from "csx";
import { keyframes } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";

const backgroundKeyframes = keyframes({
  "0%": { backgroundPosition: "0% 50%" },
  "50%": { backgroundPosition: "100% 50%" },
  "100%": { backgroundPosition: "0% 50%" },
});

export const purpleGradient: NestedCSSProperties = {
  animation: "30s linear 0s infinite",
  animationName: backgroundKeyframes,
  background: linearGradient(
    "to right",
    "#7f648f",
    "#97646d",
    "#858cb3",
    "#636584",
  ),
  backgroundSize: percent(400),
};
