import EventHeader from "@/components/EventHeader";
import { ColorHelper, em, rem } from "csx";
import { media, style } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";
import { small } from "../breakpoints";
import * as colors from "../colors";

export interface IDefaultThemeOptions {
  backgroundColor: ColorHelper;
  darkMode: boolean;
  textColorOverride?: ColorHelper;
}

export class DefaultTheme implements IEventTheme {
  public documentFontFamily = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`;
  public headingFontFamily = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`;

  protected headerFontWeight = 800;
  protected headerLineHeight = 1.1;
  protected options: IDefaultThemeOptions;

  constructor({
    darkMode = false,
    backgroundColor = colors.white,
    textColorOverride,
  }: Partial<IDefaultThemeOptions> = {}) {
    this.options = {
      backgroundColor,
      darkMode,
      textColorOverride,
    };
  }

  public get backgroundColor() {
    return this.options.backgroundColor;
  }

  public get textColor() {
    return (
      this.options.textColorOverride ??
      (this.options.darkMode ? colors.white : colors.text)
    );
  }
  public get lightTextColor() {
    return this.options.darkMode ? colors.white.fadeOut(0.5) : colors.textLight;
  }
  public get linkColor() {
    return this.options.darkMode ? colors.white : colors.link;
  }

  get documentStyle(): NestedCSSProperties {
    return {
      fontFamily: this.documentFontFamily,
    };
  }

  get body1(): NestedCSSProperties {
    return {
      color: this.textColor.toString(),
      fontSize: rem(1),
      fontWeight: 400,
    };
  }

  get body2(): NestedCSSProperties {
    return {
      color: this.textColor.toString(),
      fontSize: rem(1),
      fontWeight: this.headerFontWeight,
    };
  }

  get display2(): NestedCSSProperties {
    return {
      color: this.textColor.toString(),
      fontFamily: this.headingFontFamily,
      fontSize: rem(2.5),
      fontWeight: this.headerFontWeight,
      lineHeight: this.headerLineHeight,
    };
  }

  get display1(): NestedCSSProperties {
    return {
      color: this.textColor.toString(),
      fontFamily: this.headingFontFamily,
      fontSize: rem(2),
      fontWeight: this.headerFontWeight,
      lineHeight: this.headerLineHeight,
      ...media(
        { maxWidth: small },
        {
          fontSize: rem(2.3),
        },
      ),
    };
  }

  get title(): NestedCSSProperties {
    return {
      color: this.textColor.toString(),
      fontFamily: this.headingFontFamily,
      fontSize: rem(1.6),
      fontWeight: this.headerFontWeight,
      lineHeight: this.headerLineHeight,
    };
  }

  get smallTitle(): NestedCSSProperties {
    return {
      color: this.textColor.toString(),
      fontSize: rem(1.2),
      fontWeight: 800,
      lineHeight: this.headerLineHeight,
    };
  }

  get subtitle(): NestedCSSProperties {
    return {
      color: this.lightTextColor.toString(),
      fontFamily: this.headingFontFamily,
      fontSize: rem(1.3),
      fontWeight: 300,
      letterSpacing: em(0.1),
      lineHeight: this.headerLineHeight,
      textTransform: "uppercase",
    };
  }

  get link(): NestedCSSProperties {
    return {
      color: this.linkColor.toString(),
    };
  }

  get list(): NestedCSSProperties {
    return {
      color: this.textColor.toString(),
      paddingLeft: em(2),
    };
  }

  get table(): NestedCSSProperties {
    return {
      border: `2px solid ${colors.borderGrey.toString()}`,
      padding: em(0.25),
      textAlign: "left",

      $nest: {
        "th, td": {
          padding: em(0.5),
        },
      },
    };
  }

  get card(): NestedCSSProperties {
    return {
      backgroundColor: colors.bgLightGrey.toString(),
      borderRadius: em(0.2),
      boxShadow: [
        em(0),
        em(0.3),
        em(0.8),
        colors.shadow.fadeOut(0.3).toString(),
      ].join(" "),
      boxSizing: "border-box",
      padding: em(2),
    };
  }

  get wysiwyg(): NestedCSSProperties {
    return {
      $nest: {
        a: this.link,
        h1: this.display1,
        h2: this.title,
        h3: this.smallTitle,
        h4: this.body2,
        ol: this.list,
        table: this.table,
        ul: this.list,
      },
    };
  }

  public get classes(): IEventThemeClasses {
    return {
      body1: style(this.body1),
      body2: style(this.body2),
      display1: style(this.display1),
      display2: style(this.display2),
      document: style(this.documentStyle),
      link: style(this.link),
      list: style(this.list),
      smallTitle: style(this.smallTitle),
      subtitle: style(this.subtitle),
      table: style(this.table),
      title: style(this.title),
      wysiwyg: style(this.wysiwyg),
    };
  }

  public get light(): IEventTheme {
    return new DefaultTheme({ darkMode: false });
  }
  public get dark(): IEventTheme {
    return new DefaultTheme({ darkMode: true, backgroundColor: colors.text });
  }
  public get alternate1(): IEventTheme {
    return this.light;
  }
  public get alternate2(): IEventTheme {
    return this.light;
  }
  public get footer(): IEventTheme {
    return this.light;
  }

  public get header(): typeof EventHeader {
    return EventHeader;
  }
}
