import { scrollToElement } from "@/lib/scrollToElement";
import { medium } from "@/styles/breakpoints";
import { em } from "csx";
import { media, style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Contents extends Vue {
  @Prop({ required: true }) public theme!: IEventTheme;
  @Prop({ required: true }) public event!: IEvent;

  public get styles() {
    return {
      ...this.theme.classes,

      root: style(
        {
          $nest: {
            a: this.theme.link,
            h3: {
              marginBottom: em(0.5),
              marginTop: 0,
            },
          },
        },
        media({ maxWidth: medium }, { marginLeft: 0 }),
      ),

      list: style({
        listStyle: "none",
        paddingLeft: 0,
      }),
    };
  }

  public scrollToSection(event: MouseEvent) {
    event.preventDefault();

    const target = event.target as HTMLAnchorElement;
    const href = target.getAttribute("href")!;
    const section = document.querySelector(href)!;
    scrollToElement(section);

    if (history.pushState) {
      history.pushState(null, "", href);
    }
  }

  public render() {
    const classes = this.styles;
    const eventFields = this.event.fields;

    return (
      <div class={classes.root}>
        <h3 class={classes.title}>Contents</h3>
        <ol class={classes.list}>
          {eventFields.sections
            .filter(s => !!s.fields)
            .map(section => (
              <li>
                <a
                  href={`#${section.fields.slug}`}
                  onClick={this.scrollToSection}
                >
                  {section.fields.title}
                </a>
              </li>
            ))}
        </ol>
      </div>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-53b33d0a/Contents.tsx" });