import { color, rgba } from "csx";

export const black = color("#000");
export const white = color("#fff");

export const text = color("#333");
export const textLight = color("#777");
export const link = color("#0062ff");

export const borderGrey = color("#ddd");

export const bgLightGrey = black.lighten(0.95);
export const bgTranslucentBlack = rgba(0, 0, 0, 0.2);
export const bgTranslucentWhite = rgba(255, 255, 255, 0.5);

export const shadow = rgba(0, 0, 0, 0.5);
