import AriseConf20Header from "@/components/headers/AriseConf20Header";
import { color, rem } from "csx";
import { media } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";
import { small } from "../breakpoints";
import { white } from "../colors";
import { DefaultTheme } from "./DefaultTheme";

const orange = color("rgb(207,105,40)");

export interface IAriseConf20ThemeOptions {
  alternateMode: boolean;
}

export class AriseConf20Theme extends DefaultTheme {
  public documentFontFamily = "acumin-pro, sans-serif";
  public headingFontFamily = "minerva-modern, serif";
  public headingSansFontFamily = "acumin-pro, sans-serif";

  protected headerFontWeight = 700;

  get header() {
    return AriseConf20Header;
  }

  public injectFonts() {
    import("../../assets/scss/fonts/ariseconf20.scss");
  }

  public get linkColor() {
    return this.options.darkMode ? white : orange;
  }

  get body1(): NestedCSSProperties {
    return {
      ...super.body1,
      fontWeight: 500,
    };
  }

  get body2(): NestedCSSProperties {
    return {
      ...this.body1,
      fontWeight: 800,
    };
  }

  get display2(): NestedCSSProperties {
    return {
      color: orange.toString(),
      fontFamily: this.headingSansFontFamily,
      fontSize: rem(2.5),
      fontWeight: 800,
      lineHeight: this.headerLineHeight,
      textTransform: "uppercase",
    };
  }

  get display1(): NestedCSSProperties {
    return {
      color: this.textColor.toString(),
      fontFamily: this.headingFontFamily,
      fontSize: rem(2),
      fontWeight: this.headerFontWeight,
      lineHeight: this.headerLineHeight,
      textTransform: "uppercase",
      ...media(
        { maxWidth: small },
        {
          fontSize: rem(2.3),
        },
      ),
    };
  }

  get title(): NestedCSSProperties {
    return {
      color: this.textColor.toString(),
      fontFamily: this.headingFontFamily,
      fontSize: rem(1.6),
      fontWeight: this.headerFontWeight,
      lineHeight: this.headerLineHeight,
      textTransform: "uppercase",
    };
  }

  public get light(): IEventTheme {
    return new AriseConf20Theme({ darkMode: false });
  }
  public get dark(): IEventTheme {
    return new AriseConf20Theme({
      darkMode: true,
    });
  }
}
