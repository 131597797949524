import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes: [
    {
      component: () => import(/* webpackChunkName: "event" */ "./views/Event"),
      name: "event",
      path: "/:slug",
    },
  ],
});
