import { AriseConf19Theme } from "./themes/AriseConf19Theme";
import { AriseConf20Theme } from "./themes/AriseConf20Theme";
import { DefaultTheme } from "./themes/DefaultTheme";
import { Passionate2019Theme } from "./themes/Passionate2019Theme";
import { Passionate2020Theme } from "./themes/Passionate2020Theme";
import { Passionate2021Theme } from "./themes/Passionate2021Theme";
import { Passionate2022Theme } from "./themes/Passionate2022Theme";

export const themes: { [name: string]: IEventTheme } = {
  ariseconf19: new AriseConf19Theme(),
  ariseconf20: new AriseConf20Theme(),
  default: new DefaultTheme(),
  passionate2019: new Passionate2019Theme(),
  passionate2020: new Passionate2020Theme(),
  passionate2021: new Passionate2021Theme(),
  passionate2022: new Passionate2022Theme(),
};
