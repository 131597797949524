import Passionate2020Header from "@/components/headers/Passionate2020Header";
import { color, em } from "csx";
import { NestedCSSProperties } from "typestyle/lib/types";
import { DefaultTheme, IDefaultThemeOptions } from "./DefaultTheme";

export const passionateNavy = color("#202a51");
export const passionateLightGreen = color("#f3f2e2");
export const passionateText = color("#fffef0");
export const passionateSalmon = color("#efc0b7");

export class Passionate2020Theme extends DefaultTheme {
  public documentFontFamily = `"Domaine Sans Text", sans-serif`;
  public headingFontFamily = `"Domaine Display", sans-serif`;
  protected headerFontWeight = 500;

  public get textColor() {
    return this.options.darkMode ? passionateText : passionateNavy;
  }
  public get lightTextColor() {
    return this.textColor.fadeOut(0.25);
  }
  public get linkColor() {
    return this.textColor;
  }
  public get card(): NestedCSSProperties {
    return {
      ...super.card,
      borderRadius: em(0.2),
    };
  }

  public get light(): IEventTheme {
    return new Passionate2020Theme({
      backgroundColor: passionateLightGreen,
      darkMode: false,
    });
  }
  public get dark(): IEventTheme {
    return new Passionate2020Theme({
      backgroundColor: passionateNavy,
      darkMode: true,
    });
  }

  public get header(): typeof Passionate2020Header {
    return Passionate2020Header;
  }

  public injectFonts() {
    import("../../assets/scss/fonts/domaine.scss");
  }
}
