import GoogleMapsLoader from "google-maps";
import Vue from "vue";
import Meta from "vue-meta";
import { App } from "./App";
import router from "./router";
import store from "./store";
// import "./registerServiceWorker";

GoogleMapsLoader.KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY!;
GoogleMapsLoader.VERSION = "";

Vue.config.productionTip = false;

Vue.use(Meta);

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount("#app");
