import { percent, px, viewHeight } from "csx";
import { style } from "typestyle";
import { CreateElement } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FullHeight extends Vue {
  @Prop({ default: "div" }) public tagName!: string;

  private get styles() {
    const isMobile = typeof window.orientation !== "undefined";

    return {
      root: style({
        minHeight: isMobile ? px(window.innerHeight) : viewHeight(100),
        position: "relative",
        width: percent(100),
      }),
    };
  }

  public render(h: CreateElement) {
    const classes = this.styles;
    return h(
      this.tagName,
      {
        class: classes.root,
      },
      this.$slots.default,
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-48a30f46/FullHeight.tsx" });