import { content } from "@/styles/content";
import { passionateNavy } from "@/styles/themes/Passionate2020Theme";
import { em } from "csx";
import { style } from "typestyle";
import { Component } from "vue-property-decorator";
import BackToWebsite from "../BackToWebsite";
import Contents from "../Contents";
import EventHeader from "../EventHeader";

@Component
export default class Passionate2020Header extends EventHeader {
  public get styles() {
    return {
      ...this.theme.classes,

      root: style({
        $nest: {
          h1: {
            marginTop: 0,
          },
        },
        backgroundColor: passionateNavy.toString(),
        paddingBottom: em(3),
        paddingTop: em(7),
      }),

      headerContent: style(content),
    };
  }

  public render() {
    const event = this.event;
    const theme = this.theme;
    const eventFields = event.fields;
    const classes = this.styles;

    return (
      <header id="top" class={classes.root}>
        <BackToWebsite props={{ event, color: theme.textColor }} />
        <div class={classes.headerContent}>
          <h2 class={classes.subtitle}>Delegate Info</h2>
          <h1 class={classes.display2}>{eventFields.name}</h1>

          <Contents props={{ event, theme }} />
        </div>
      </header>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-37f7d89d/Passionate2020Header.tsx" });