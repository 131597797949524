import { black } from "@/styles/colors";
import { content } from "@/styles/content";
import { em, url } from "csx";
import { style } from "typestyle";
import { Component } from "vue-property-decorator";
import EventHeader from "../EventHeader";

@Component
export default class AriseConf20Header extends EventHeader {
  public get styles() {
    return {
      ...this.theme.classes,

      root: style({
        $nest: {
          h1: {
            marginBottom: em(1.5),
            marginTop: 0,
          },
          h2: {
            marginBottom: em(0.2),
          },
        },
        backgroundColor: black.toString(),
        backgroundImage: url(this.backgroundImageURL),
        backgroundPosition: "50% 70%",
        backgroundSize: "cover",
        boxSizing: "border-box",
        paddingBottom: em(3),
        paddingTop: em(7),
      }),

      headerContent: style(content),
    };
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-e4452ed4/AriseConf20Header.tsx" });