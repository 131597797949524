import Passionate2021Header from "@/components/headers/Passionate2021Header";
import { color, em } from "csx";
import { NestedCSSProperties } from "typestyle/lib/types";
import { black, white } from "../colors";
import { DefaultTheme } from "./DefaultTheme";

export const passionateBlue = color("#88afdc");
export const passionateCream = color("#F9F9EF");
export const passionateOrange = color("#f05b22");
export const passionateLightOrange = color("#fac2ac");

export class Passionate2021Theme extends DefaultTheme {
  public documentFontFamily = `"PP Woodland", sans-serif`;
  public headingFontFamily = `"PP Woodland", sans-serif`;
  protected headerFontWeight = 300;

  public get textColor() {
    return this.options.darkMode ? passionateCream : black;
  }
  public get lightTextColor() {
    return this.textColor.fadeOut(0.25);
  }
  public get linkColor() {
    return this.textColor;
  }
  public get card(): NestedCSSProperties {
    return {
      ...super.card,
      borderRadius: em(0.2),
    };
  }

  public get light(): IEventTheme {
    return new Passionate2021Theme({
      backgroundColor: passionateCream,
      darkMode: false,
    });
  }
  public get dark(): IEventTheme {
    return new Passionate2021Theme({
      backgroundColor: passionateBlue,
      darkMode: true,
    });
  }

  public get footer(): IEventTheme {
    return new Passionate2021Theme({
      backgroundColor: passionateLightOrange,
      darkMode: false,
    });
  }

  public get header(): typeof Passionate2021Header {
    return Passionate2021Header;
  }

  public injectFonts() {
    import("../../assets/scss/fonts/ppwoodland.scss");
  }
}
