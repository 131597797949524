import { color, em } from "csx";
import { NestedCSSProperties } from "typestyle/lib/types";
import { white } from "../colors";
import { DefaultTheme, IDefaultThemeOptions } from "./DefaultTheme";

const passionateBrown = color("#aa6649");
const passionateLightBrown = color("#e2d2c3");
const passionateAlternateTextColor = color("#a26a4c");

export interface IPassionate2019ThemeOptions {
  alternateMode: boolean;
}

export class Passionate2019Theme extends DefaultTheme {
  public documentFontFamily = `Berthold, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`;
  public headingFontFamily = `Berthold, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`;

  protected headerFontWeight = 400;
  private alternateMode: boolean;

  constructor(
    options: Partial<IPassionate2019ThemeOptions & IDefaultThemeOptions> = {},
  ) {
    super(options);
    this.alternateMode = options.alternateMode || false;
  }

  public get textColor() {
    if (this.alternateMode) {
      return passionateAlternateTextColor;
    }
    return super.textColor;
  }
  public get lightTextColor() {
    if (this.alternateMode) {
      return passionateAlternateTextColor.fadeOut(0.25);
    }
    return super.lightTextColor;
  }
  public get linkColor() {
    return this.options.darkMode ? white : passionateAlternateTextColor;
  }

  public get light(): IEventTheme {
    return new Passionate2019Theme({ darkMode: false });
  }
  public get dark(): IEventTheme {
    return new Passionate2019Theme({
      backgroundColor: passionateBrown,
      darkMode: true,
    });
  }
  public get alternate1(): IEventTheme {
    return new Passionate2019Theme({
      alternateMode: true,
      darkMode: false,
    });
  }
  public get alternate2(): IEventTheme {
    return new Passionate2019Theme({
      alternateMode: true,
      backgroundColor: passionateLightBrown,
      darkMode: false,
    });
  }

  public injectFonts() {
    import("../../assets/scss/fonts/berthold.scss");
  }
}
