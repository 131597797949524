import { color } from "csx";
import { NestedCSSProperties } from "typestyle/lib/types";
import { black, white } from "../colors";
import { DefaultTheme } from "./DefaultTheme";

export const passionateCream = color("#F6EDD7");
export const passionatePink = color("rgb(246, 154, 155)");
export const passionateBrown = color("rgb(134, 74, 31)");

export class Passionate2022Theme extends DefaultTheme {
  public documentFontFamily = `Avenir, avenir, avenir next, Helvetica, san-serif`;
  public headingFontFamily = `Quarantype Hopscotch, sans-serif`;
  protected headerFontWeight = 400;

  public get textColor() {
    return (
      this.options.textColorOverride ??
      (this.options.darkMode ? passionateCream : passionateBrown)
    );
  }
  public get lightTextColor() {
    return this.textColor;
  }
  public get linkColor() {
    return this.textColor;
  }
  public get card() {
    return {
      ...super.card,
      backgroundColor: white.toString(),
    };
  }

  public get subtitle(): NestedCSSProperties {
    return {
      ...super.subtitle,
      fontFamily: this.documentFontFamily,
    };
  }

  public get light(): IEventTheme {
    return new Passionate2022Theme({
      backgroundColor: passionateCream,
      darkMode: false,
    });
  }
  public get dark(): IEventTheme {
    return new Passionate2022Theme({
      backgroundColor: passionatePink,
      darkMode: true,
    });
  }

  public get footer(): IEventTheme {
    return new Passionate2022Theme({
      backgroundColor: passionateCream,
      darkMode: false,
      textColorOverride: black,
    });
  }

  public injectFonts() {
    import("../../assets/scss/fonts/avenir.scss");
    import("../../assets/scss/fonts/hopscotch.scss");
  }
}
