import "@/assets/scss/main.scss";
import "normalize.css/normalize.css";
import { Component, Vue } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "Home",
    titleTemplate: "%s | Delegate Info",
  },
})
export class App extends Vue {
  public render() {
    return (
      <div id="app" class="theme-base">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-24470c10/App.tsx" });