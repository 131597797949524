import FullHeight from "@/components/FullHeight";
import { content } from "@/styles/content";
import { em, url, viewHeight } from "csx";
import { style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";
import BackToWebsite from "./BackToWebsite";
import Contents from "./Contents";

@Component({
  components: {
    FullHeight,
  },
})
export default class EventHeader extends Vue {
  @Prop({ required: true }) public event!: IEvent;
  @Prop({ required: true }) public theme!: IEventTheme;

  public get styles() {
    return {
      ...this.theme.classes,

      root: style({
        $nest: {
          h1: {
            marginTop: 0,
          },
        },
        backgroundImage: url(this.backgroundImageURL),
        backgroundPosition: "center",
        backgroundSize: "cover",
        boxSizing: "border-box",
        paddingBottom: em(3),
        paddingTop: em(7),
      }),

      headerContent: style(content),
    };
  }

  public get backgroundImageURL(): string {
    if (!this.event.fields.backgroundImage) {
      return "";
    }

    return `${
      this.event.fields.backgroundImage.fields.file.url
    }?fm=jpg&q=70&w=1920&fit=fill`;
  }

  public render() {
    const event = this.event;
    const theme = this.theme;
    const eventFields = event.fields;
    const classes = this.styles;

    return (
      <header id="top" class={classes.root}>
        <BackToWebsite props={{ event, theme }} />
        <div class={classes.headerContent}>
          <h2 class={classes.subtitle}>More Info</h2>
          <h1 class={classes.display2}>{eventFields.name}</h1>

          <Contents props={{ event, theme }} />
        </div>
      </header>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-1ea0470d/EventHeader.tsx" });