import { em, percent, px, viewHeight } from "csx";
import { NestedCSSProperties } from "typestyle/lib/types";

export const content: NestedCSSProperties = {
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: px(1100),
  width: percent(80),
};

export const section: NestedCSSProperties = {
  paddingBottom: em(7),
  paddingTop: em(7),
  position: "relative",
};

export const textContent: NestedCSSProperties = {
  maxWidth: em(35),
};

export const fullScreen: NestedCSSProperties = {
  height: viewHeight(100),
  overflow: "hidden",
  position: "relative",
  width: percent(100),
};
