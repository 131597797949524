import { white } from "@/styles/colors";
import { ColorHelper, em } from "csx";
import { style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BackToWebsite extends Vue {
  @Prop({ required: true }) public event!: IEvent;
  @Prop({ default: () => white.fadeOut(0.4) }) public color!: ColorHelper;

  public render() {
    return (
      <a href={this.event.fields.website} class={this.styles.root}>
        &larr; Back to website
      </a>
    );
  }

  private get styles() {
    return {
      root: style({
        color: this.color.toString(),
        left: em(2),
        position: "absolute",
        top: em(2),
      }),
    };
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-53e915ff/BackToWebsite.tsx" });